import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import { CurrentStatusPill } from "@/components/status-pill"
import { formatValue } from "@/helpers/valueFormat"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { CallViewHistoryTabs } from "./history-tabs"
import { useContactProfileHook } from "./hook"

const StudentInfoItem: FunctionComponent<{
  isSentryUnmask?: boolean
  labelKey: string
  value: React.ReactNode
}> = ({ isSentryUnmask = false, labelKey, value }) => {
  const { t } = useTranslation()

  const styles = {
    card: "flex flex-col gap-y-2 w-1/2 grow",
    label: "w-full font-label-small text-neutral-600",
    value: "w-full font-body-medium-regular text-neutral-700",
  }

  return (
    <div className={styles.card}>
      <div className={styles.label} data-sentry-unmask>
        {t(labelKey)}
      </div>
      <div
        className={styles.value}
        {...(isSentryUnmask && { "data-sentry-unmask": true })}
      >
        {value}
      </div>
    </div>
  )
}

const styles = {
  container:
    "flex flex-col border border-neutral-300 rounded h-auto col-span-full lg:col-span-3 lg:row-start-2 lg:row-end-[-1]",
  caseDetails:
    "px-6 pb-6 pt-4 flex flex-col gap-y-6 bg-neutral-100 border-b border-neutral-300",
  studentIdentifierContainer: "flex flex-col gap-y-1",
  entorIdContainer:
    "flex gap-x-1 items-center font-label-small text-neutral-500",
  studentName: "font-h1-bold text-neutral-700",
  labelsContainer: "flex gap-x-2 items-center",
  infoIcon: "text-primary-200",
  studentInfo: "flex flex-col gap-y-4",
  studentInfoSplitContainer: "flex gap-x-4 w-full",
  historyTitleContainer:
    "px-6 py-2 flex gap-x-2 bg-neutral-100 border-b border-neutral-300 items-center font-body-large-regular text-neutral-600",
  historyTitleItem: "font-body-large-bold text-neutral-700",
}

interface Props {
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  historyItem: connectPanelValue.ActivityFeedEntry.ActivityFeedEntryType | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}

const ContactProfileView: FunctionComponent<Props> = ({
  currentCase,
  historyItem,
  studentProfile,
}) => {
  const { t } = useTranslation()
  const { contactName, contactNameKana, historyEntries } =
    useContactProfileHook({
      currentCase,
      studentProfile,
      historyItem,
    })

  return (
    <div className={styles.container}>
      <div className={styles.caseDetails}>
        <div className={styles.studentIdentifierContainer}>
          <div className={styles.entorIdContainer} data-sentry-unmask>
            <span>{t("caseHistory.entorId")}:</span>
            <span>{formatValue(studentProfile?.legacy_id)}</span>
          </div>
          <h1 className={styles.studentName}>{contactName}</h1>
        </div>
        <div className={styles.studentInfo}>
          <div className={styles.studentInfoSplitContainer}>
            <StudentInfoItem
              labelKey="caseHistory.nameFurigana"
              value={contactNameKana}
            />
            <StudentInfoItem
              labelKey="callResult.status"
              value={
                currentCase?.case.current_status ? (
                  <CurrentStatusPill
                    current={currentCase?.case.current_status}
                    latestActionTaken={
                      currentCase?.case.latest_status_action_taken
                    }
                  />
                ) : (
                  "-"
                )
              }
              isSentryUnmask
            />
          </div>
          <div className={styles.studentInfoSplitContainer}>
            <StudentInfoItem
              labelKey="caseHistory.university"
              value={formatValue(studentProfile?.university)}
            />
            <StudentInfoItem
              labelKey="caseHistory.department"
              value={formatValue(studentProfile?.department)}
            />
          </div>
          <div className={styles.studentInfoSplitContainer}>
            <StudentInfoItem
              labelKey="caseHistory.graduation"
              value={formatValue(studentProfile?.graduation_year)}
            />
            <StudentInfoItem
              labelKey="caseHistory.email"
              value={formatValue(studentProfile?.email)}
            />
          </div>
        </div>
      </div>
      <CallViewHistoryTabs
        studentCase={currentCase?.case}
        historyEntries={historyEntries}
      />
    </div>
  )
}

export default ContactProfileView
