import { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"

import CallReportForm from "@/components/ACWForm"
import CallPanel from "@/components/CallPanel"
import ProfileView from "@/components/ContactProfileView"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

import { useACWButtonsHook } from "../CallPanel/ACWButtons/hook"
import { useCallPanelHook } from "../CallPanel/hook"
import { useCallViewHook } from "./hook"

const styles = {
  container:
    "h-full w-full grid grid-cols-5 gap-x-6 gap-y-2 px-12 pb-10 lg:px-34.5 lg:grid-rows-call-view",
  card: "bg-neutral-100 rounded border border-neutral-300 shadow-card px-4 py-6",
  uuidContainer:
    "row-start-1 col-span-full px-4 pt-3 pb-1 font-label-small text-neutral-500 flex gap-x-2 items-center flex-wrap",
  uuid: "flex gap-x-1 items-center",
}

interface Props {
  caseStatuses: connectPanelValue.Decoder.CaseStatuses | null
  currentCase: connectPanelValue.Decoder.CaseAndActivityFeeds | null
  studentProfile: connectPanelValue.Decoder.StudentProfile | null
}

export type CallReportModeType =
  | "add-status"
  | "automatic-call-mode"
  | "manual-call-mode"
  | "skip-call"

const CallView: FunctionComponent<Props> = ({
  caseStatuses,
  currentCase,
  studentProfile,
}) => {
  const {
    form,
    formattedTaskId,
    formattedVoiceId,
    historyItem,
    setHistoryItem,
    studentId,
  } = useCallViewHook({
    currentCase,
    studentProfile,
  })

  const callPanelState = useCallPanelHook({
    campaignName: currentCase?.case?.campaign_id || "",
    phoneNumber: studentProfile?.phone_number,
  })

  const callReportMode =
    callPanelState.displayMode.current === "connected-task"
      ? callPanelState.displayMode.state.callReportMode
      : "automatic-call-mode"

  const acwButtonState = useACWButtonsHook({
    currentCase,
    getFormValues: form.getValues,
    isInboundCall:
      callPanelState.displayMode.current === "acw" &&
      callPanelState.displayMode.state.isInboundCall,
    timer: callPanelState.timer,
  })

  const { t } = useTranslation()

  return (
    <div className={styles.container} data-testid="call-view">
      <div className={styles.uuidContainer} data-sentry-unmask>
        <div className={styles.uuid}>
          <span>{t("ids.taskId")}</span>
          <span>{formattedTaskId}</span>
        </div>
        <div className={styles.uuid}>
          <span>{t("ids.voiceId")}</span>
          <span>{formattedVoiceId}</span>
        </div>
        <div className={styles.uuid}>
          <span>{t("ids.uuid")}</span>
          <span>{studentId}</span>
        </div>
      </div>

      <ProfileView
        currentCase={currentCase}
        studentProfile={studentProfile}
        historyItem={historyItem}
      />
      <CallPanel
        acwButtonState={acwButtonState}
        callPanelState={callPanelState}
        currentCase={currentCase}
        historyItem={historyItem}
        setHistoryItem={setHistoryItem}
      />
      <CallReportForm
        className={styles.card}
        caseStatuses={caseStatuses}
        form={form}
        historyItem={historyItem}
        setHistoryItem={setHistoryItem}
        currentCase={currentCase}
        callReportMode={callReportMode}
      />
    </div>
  )
}

export default CallView
