import * as layoutValues from "@/components/layout/value"
import { useFormValidation } from "@/hooks/formValidation"

interface HeaderHookInputType {
  agentProfile: layoutValues.Decoder.ConnectUserInformation | null
}

interface HeaderHookType {
  agentKind?: string
  agentName: string
}

const useHeaderHook = ({
  agentProfile,
}: HeaderHookInputType): HeaderHookType => {
  const { isKanji } = useFormValidation()

  const firstName = agentProfile?.identity_info.first_name ?? ""
  const lastName = agentProfile?.identity_info.last_name ?? ""

  const agentName = isKanji(lastName || firstName)
    ? `${lastName}${firstName}`
    : `${firstName} ${lastName}`

  const agentKind = agentProfile?.security_profile_names[0].toLowerCase()

  return { agentName, agentKind }
}

export { useHeaderHook }
