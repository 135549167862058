import { z } from "zod"

import { hasValue } from "@/helpers/typeguards"

const idSchema = z.string().uuid()

// No global! https://stackoverflow.com/a/11727400
const caseIdRegexp =
  /^(?<campaign>.*)-(?<studentId>[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12})$/
const caseIdSchema = z.string().regex(caseIdRegexp)

const connectUserIdSchema = z.union([idSchema, z.literal("system")])

// TODO: we may not need this abstraction
const datetimeSchema = z.coerce.date()

const encodeProcessingTimeDataSchema = z.object({
  updated_at_timestamp: z.string().datetime(),
  contactId: z.string().uuid(),
  prev_duration: z.number(),
})

const decodeProcessingTimeDataSchema = encodeProcessingTimeDataSchema.transform(
  (val) => {
    return {
      ...val,
      updated_at_timestamp: new Date(val.updated_at_timestamp),
    }
  },
)

type EncodeProcessingTimeData = z.infer<typeof encodeProcessingTimeDataSchema>
type DecodeProcessingTimeData = z.infer<typeof decodeProcessingTimeDataSchema>

const callDurationDataSchema = z.object({
  contact_id: z.string().uuid(),
  call_start_timestamp: z.string().datetime(),
  call_end_timestamp: z.string().datetime().nullable(),
})

type CallDurationData = z.infer<typeof callDurationDataSchema>

const getCaseParamsSchema = z
  .object({
    student_id: z
      .string()
      .uuid()
      .nullish()
      .transform((value) => value ?? undefined),
    phone_number: z
      .string()
      .min(1)
      .nullish()
      .transform((value) => value ?? undefined),
    campaign_id: z.string().min(1),
  })
  .refine(
    ({ campaign_id, phone_number, student_id }) => {
      const isValidTaskQuery = hasValue(campaign_id) && hasValue(student_id)
      const isValidVoiceCallQuery =
        hasValue(campaign_id) && hasValue(phone_number)

      return isValidTaskQuery || isValidVoiceCallQuery
    },
    {
      message: "Must have a valid combination of query parameters",
    },
  )

type GetCaseParams = z.infer<typeof getCaseParamsSchema>

export {
  type CallDurationData,
  callDurationDataSchema,
  caseIdRegexp,
  caseIdSchema,
  connectUserIdSchema,
  datetimeSchema,
  type DecodeProcessingTimeData,
  decodeProcessingTimeDataSchema,
  type EncodeProcessingTimeData,
  encodeProcessingTimeDataSchema,
  type GetCaseParams,
  getCaseParamsSchema,
  idSchema,
}
