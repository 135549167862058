import { FunctionComponent } from "react"
import { NavLink } from "react-router-dom"
import { format } from "date-fns"

import {
  buildDate,
  versionNumber,
} from "@/components/core/AppUpdateChecker/hook-app-update"
import Icon from "@/components/Icon"
import { cn } from "@/helpers/classNames"

import { useSidebarHook } from "./hook"

const styles = {
  transitionWrapper: (isHidden: boolean) =>
    cn([
      "flex shrink-0 flex-col justify-between border-r border-neutral-300 bg-neutral-100 transition-[width] duration-300 ease-out",
      isHidden ? "w-0" : "w-72",
    ]),
  container: "w-full px-4 py-10 flex flex-col gap-y box-border",
  link: (selected: boolean) =>
    cn([
      "font-button-medium px-4 py-2 text-neutral-600 hover:bg-primary-100 hover:text-neutral-700",
      selected && "rounded bg-primary-100 text-neutral-700",
    ]),
  labelContainer: "flex flex-row items-center gap-x-5",
  emptyIcon: "w-4 h-4",
}

const Sidebar: FunctionComponent = () => {
  const { isHiddenSidebar, navigationRoutes } = useSidebarHook()

  return (
    <div className={styles.transitionWrapper(isHiddenSidebar)}>
      <div className={styles.container}>
        {navigationRoutes
          .filter((route) => route.isEnabled())
          .map((route, index) => {
            return (
              <NavLink
                key={index}
                to={{
                  pathname: route.path,
                  search: route.searchParams || "",
                }}
                className={({ isActive }) => styles.link(isActive)}
              >
                <div className={styles.labelContainer} data-sentry-unmask>
                  {route.icon && <Icon name={route.icon} />}
                  <p>{route.name}</p>
                </div>
              </NavLink>
            )
          })}
      </div>

      <div>
        <AppVersionInfo />
      </div>
    </div>
  )
}

function AppVersionInfo() {
  return (
    <div className="mx-6 flex flex-col items-center gap-2 border-t py-6 text-xs text-muted-foreground">
      <div>Version {versionNumber}</div>
      <div>{format(new Date(buildDate), "yyyy/MM/dd HH:mm")}</div>
    </div>
  )
}

export default Sidebar
