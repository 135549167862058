import { ReplayFrameEvent } from "@sentry/react"
import { ErrorEvent } from "@sentry/types"

import { hasProperty, hasValue, isObject, isString } from "@/helpers/typeguards"

export const beforeSend = (event: ErrorEvent) => {
  if (
    hasValue(event) &&
    hasProperty("contexts", isObject)(event) &&
    hasProperty("AxiosError", isObject)(event.contexts) &&
    hasProperty("name", isString)(event.contexts.AxiosError) &&
    event.contexts.AxiosError.name === "AxiosError"
  ) {
    const newContexts: Record<string, unknown> = {}

    if (hasProperty("code")(event.contexts.AxiosError)) {
      newContexts.code = event.contexts.AxiosError.code
    }
    if (hasProperty("message")(event.contexts.AxiosError)) {
      newContexts.message = event.contexts.AxiosError.message
    }
    if (hasProperty("name")(event.contexts.AxiosError)) {
      newContexts.name = event.contexts.AxiosError.name
    }
    if (hasProperty("stack")(event.contexts.AxiosError)) {
      newContexts.stack = event.contexts.AxiosError.stack
    }
    if (hasProperty("status")(event.contexts.AxiosError)) {
      newContexts.status = event.contexts.AxiosError.status
    }

    if (hasProperty("config", isObject)(event.contexts.AxiosError)) {
      if (hasProperty("method")(event.contexts.AxiosError.config)) {
        newContexts.method = event.contexts.AxiosError.config.method
      }

      if (hasProperty("params")(event.contexts.AxiosError.config)) {
        newContexts.params = event.contexts.AxiosError.config.params
      }

      if (hasProperty("url")(event.contexts.AxiosError.config)) {
        newContexts.url = event.contexts.AxiosError.config.url
      }
    }

    if (
      hasProperty("request", isObject)(event.contexts.AxiosError) &&
      hasProperty("__sentry_xhr_v3__")(event.contexts.AxiosError.request)
    ) {
      newContexts.request = event.contexts.AxiosError.request.__sentry_xhr_v3__
    }

    if (
      hasProperty("response", isObject)(event.contexts.AxiosError) &&
      hasProperty("data")(event.contexts.AxiosError.response)
    ) {
      newContexts.response = event.contexts.AxiosError.response.data
    }

    event.contexts.AxiosError = newContexts

    return event
  }

  return event
}

/**
 * Function to filters out the request and response bodies of
 * successful API requests to prevent leaking sensitive data.
 * @param event - the event to sanitize
 *
 * @returns the sanitized event if body property if found | the original event if no body property is found
 */
const sanitizeEventBody = (event: ReplayFrameEvent): ReplayFrameEvent => {
  if (
    event.data.tag === "performanceSpan" &&
    (event.data.payload.op === "resource.fetch" ||
      event.data.payload.op === "resource.xhr") &&
    event.data.payload.data?.statusCode === 200
  ) {
    if (
      hasProperty("response", isObject)(event.data.payload.data) &&
      hasProperty("body")(event.data.payload.data.response)
    ) {
      event.data.payload.data.response.body = "[Filtered]"
    }

    if (
      hasProperty("request", isObject)(event.data.payload.data) &&
      hasProperty("body")(event.data.payload.data.request)
    ) {
      event.data.payload.data.request.body = "[Filtered]"
    }
  }

  return event
}

export const beforeAddRecordingEvent = (
  event: ReplayFrameEvent,
): ReplayFrameEvent | null => {
  // Filter out bodies of successful API requests. Only log the full response body if the
  // request has failed for some reason.
  const sanitizedEvent = sanitizeEventBody(event)

  return sanitizedEvent
}
