import { useEffect, useState } from "react"
import { useLocation, useMatch, useNavigate } from "react-router-dom"
import { useAtomValue, useSetAtom } from "jotai"

import { routesConfig } from "@/config/routes"
import {
  agentAtom,
  agentStatusTypeAtom,
  currentContactIdAtom,
  modalModeAtom,
  pseudoACWAtom,
  UndocumentedAgentStateType,
} from "@/helpers/atoms"
import { useConnectActionsHook } from "@/hooks/connectActions"
import { useLogger } from "@/hooks/useLogger"
import { useManualCallAtom } from "@/hooks/useManualCallAtom"

const useAgentStatusHook = () => {
  const location = useLocation()
  const matchStudentDetailsPage = useMatch(
    "/campaigns/:campaignId/students/:studentId",
  )
  const navigate = useNavigate()
  const log = useLogger()
  const [lastEffectiveValue, setLastEffectiveValue] = useState(
    connect.AgentStateType.INIT,
  )

  const agent = useAtomValue(agentAtom)
  const currentContactId = useAtomValue(currentContactIdAtom)
  const agentStatusType = useAtomValue(agentStatusTypeAtom)
  const setModalMode = useSetAtom(modalModeAtom)
  const pseudoACW = useAtomValue(pseudoACWAtom)
  const { resetManualCallWhenOnline } = useManualCallAtom()

  const { toggleAgentStatus } = useConnectActionsHook()

  const agentConfig = agent?.getConfiguration()
  // Important: the current implementation only let users switch between "online" and "offline" statuses
  // However, more statuses can be added from AWS Connect.
  // If we needed to show more statuses in the UI, we would need to use available statuses returned by the hook.
  const agentStatuses = agentConfig
    ? agentConfig.agentStates.map(({ type }) => type)
    : []

  const getFallbackStatus = (): connect.AgentStateType => {
    if (
      agentStatusType === UndocumentedAgentStateType.SYSTEM ||
      agentStatusType === UndocumentedAgentStateType.ERROR
    ) {
      return lastEffectiveValue
    }

    return connect.AgentStateType.INIT
  }

  const selectedValue =
    agentStatuses.find((status) => status === agentStatusType) ||
    getFallbackStatus()

  useEffect(() => {
    if (selectedValue !== lastEffectiveValue) {
      setLastEffectiveValue(selectedValue)
    }
  }, [lastEffectiveValue, selectedValue])

  // disable condition:
  // - agent is offline
  // - agent has a contact(connected or connecting) or pseudoACW(not connected)
  // - in manual calling panel(student details page)
  const disabledInManualCalling =
    !!matchStudentDetailsPage &&
    lastEffectiveValue === connect.AgentStateType.OFFLINE &&
    (!!currentContactId || pseudoACW)

  const onChange = async (newState: connect.AgentStateType) => {
    try {
      const shouldDisplayGoOfflineModal =
        currentContactId && newState === connect.AgentStateType.OFFLINE

      resetManualCallWhenOnline(newState)

      if (shouldDisplayGoOfflineModal) {
        setModalMode({ mode: "goOfflineWithContact" })
      } else {
        await toggleAgentStatus({ newStateType: newState })

        // Redirect to the call panel when agent goes online outside of the call panel
        const shouldRedirectToCallPanel =
          location.pathname !== routesConfig.AUTOMATIC_CALL_PANEL &&
          newState === connect.AgentStateType.ROUTABLE

        if (shouldRedirectToCallPanel) {
          navigate(routesConfig.AUTOMATIC_CALL_PANEL, { replace: true })
        }
      }
    } catch (error) {
      log.error(error, { agent: agent?.toSnapshot() })
    }
  }

  return {
    isDisabled: disabledInManualCalling,
    selectedValue: selectedValue,
    onChange,
  }
}

export { useAgentStatusHook }
