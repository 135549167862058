import { toError } from "@/helpers/error"
import { SentryLogger } from "@/helpers/sentryLogger"

import { checkResetSession } from "./connect_session"
import {
  RefreshConnectSessionInputType,
  SetAgent,
  SoftphoneErrorHandlerInputType,
} from "./types"

const log = new SentryLogger()

const checkAndRefreshConnectSession = async (
  refreshConnectSession: RefreshConnectSessionInputType["refreshConnectSession"],
): Promise<void> => {
  const shouldResetSession = checkResetSession()

  // Re-authenticate with AWS connect; The AWS Connect refresh token can only be re-used once
  if (shouldResetSession) {
    await refreshConnectSession()
  }
}

const handleAgentRefresh = (setAgent: SetAgent) => (agent: connect.Agent) => {
  try {
    setAgent(agent)
  } catch (error) {
    log.error(error, { agent: agent.toSnapshot() })
  }
}

/** Event handler for the agent status change.
 * @param refreshConnectSession - function to refresh the AWS Connect session
 * @returns void
 *
 * Attempts to refresh the Amazon Connect token when agent goes from Offline to Available to prevent the agent from being disconnected after 10 hours of being online.
 * We didn't attempt to do this while the agent is online because we're not sure whether it might disconnect the agent while in a call;
 * TODO: Needs more testing and research to make the token refresh in all kinds of agent states.
 */
const handleAgentStateChange =
  ({ refreshConnectSession }: RefreshConnectSessionInputType) =>
  async ({
    agent,
    newState,
    oldState,
  }: connect.AgentStateChange): Promise<void> => {
    try {
      if (oldState === "Offline" && newState === "Available") {
        await checkAndRefreshConnectSession(refreshConnectSession)
      }
    } catch (error) {
      log.error(error, { agent: agent.toSnapshot() })
    }
  }

/**
 * Event handler triggered whenever the iframe throws a softphone error. We've first encountered this when
 * agent's settings in the browser have microphone access disabled. See https://app.clickup.com/t/7508642/POC-4339
 *
 */
const handleSoftphoneError =
  ({ agentRef, setModalMode }: SoftphoneErrorHandlerInputType) =>
  (initialError: connect.SoftphoneError): void => {
    try {
      const isMicError =
        initialError.errorType ===
        connect.SoftphoneErrorTypes.MICROPHONE_NOT_SHARED

      if (isMicError) {
        setModalMode({ mode: "microphoneNotShared" })
      } else {
        // show modal to inform the agent to refresh the page or contact the manager
        setModalMode({
          mode: "unexpectedSoftphoneError",
          state: { errorType: initialError.errorType },
        })

        const errorMessage = `Unexpected softphone error: ${initialError.errorType} - ${initialError.errorMessage}`
        throw new Error(errorMessage, { cause: toError(initialError) })
      }
    } catch (error) {
      const agent = agentRef.current

      log.error(error, {
        agent: agent?.toSnapshot(),
        isSoftphoneError: true,
      })
    }
  }

export { handleAgentRefresh, handleAgentStateChange, handleSoftphoneError }
