import { useTranslation } from "react-i18next"
import { ChevronLeftIcon } from "lucide-react"

import { DelayedClickButton } from "@/components/DelayedButton"
import Icon from "@/components/Icon"
import { SingleClickButton } from "@/components/SingleClickButton"
import { Button } from "@/components/ui/button"
import { TooltipWrapper } from "@/components/ui/tooltip"
import { isUndefined } from "@/helpers/typeguards"
import { CaseWithActivityFeed } from "@/pages/Campaigns/student-details/student-details-api"

import { useACWButtonsHook } from "./ACWButtons/hook"
import { useCallPanelActions } from "./callpanel-actions"
import { DisplayMode, ErrorStateMessage } from "./hook"
import { useGetEnabledCallConditions } from "./ManualCallButtons/hook"

type Props = {
  actions: ReturnType<typeof useCallPanelActions>
  acwButtonState: ReturnType<typeof useACWButtonsHook> & {
    isDisabled: boolean
    isManualCall?: boolean
  }
  campaignName?: string
  caseData?: CaseWithActivityFeed
  displayMode: DisplayMode
}

export function CallPanelButtons({
  actions,
  acwButtonState,
  campaignName,
  caseData,
  displayMode,
}: Props) {
  const {
    acceptInboundCall,
    addStatusInModal,
    backToCallMode,
    call,
    endCall,
    endTaskError,
    isMuted,
    isOnHold,
    manualCall,
    rejectInboundCall,
    skip,
    toggleHold,
    toggleMute,
  } = actions

  switch (displayMode.current) {
    case "manual-call-idle":
      if (isUndefined(campaignName) || isUndefined(caseData)) return null

      return (
        <ManualCallIdleButtons
          campaignName={campaignName}
          onAddStatus={() => addStatusInModal(caseData)}
          onCall={manualCall}
        />
      )
    case "connecting":
      return displayMode.state.isInboundCall ? (
        <InboundCallRingingButtons
          onAccept={acceptInboundCall}
          onReject={rejectInboundCall}
        />
      ) : (
        <OutboundCallRingingButtons onHangUp={endCall} />
      )
    case "connected-call":
      return (
        <InCallButtons
          isMuted={isMuted}
          isOnHold={isOnHold}
          onEndCall={endCall}
          onToggleHold={toggleHold}
          onToggleMute={toggleMute}
        />
      )
    case "connected-task":
      if (displayMode.state.callReportMode === "skip-call") {
        return (
          <SkipCallModeButtons
            isLoading={acwButtonState.isLoading}
            isDisabled={acwButtonState.isDisabled}
            onEndTask={() => acwButtonState.onClick(false)}
            onGoBack={backToCallMode}
          />
        )
      } else {
        return <ConnectedTaskButtons onCall={call} onSkipCall={skip} />
      }
    case "acw":
      return <ACWButtons {...acwButtonState} />
    case "error":
      return (
        <ErrorButtons
          onEndTaskError={endTaskError}
          displayModeMessage={displayMode.state.message}
        />
      )
    default:
      return null
  }
}

export function InboundCallRingingButtons({
  onAccept,
  onReject,
}: {
  onAccept: () => Promise<void>
  onReject: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <>
      <SingleClickButton variant="destructive" type="button" onClick={onReject}>
        <Icon name="end-call" />
        {t("global.button.skip")}
      </SingleClickButton>
      <SingleClickButton variant="success" type="button" onClick={onAccept}>
        <Icon name="accept-call" />
        {t("global.button.acceptInbound")}
      </SingleClickButton>
    </>
  )
}

export function OutboundCallRingingButtons({
  onHangUp,
}: {
  onHangUp: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <SingleClickButton variant="destructive" type="button" onClick={onHangUp}>
      <Icon name="end-call" />
      {t("global.button.endCall")}
    </SingleClickButton>
  )
}

export function InCallButtons({
  isMuted,
  isOnHold,
  onEndCall,
  onToggleHold,
  onToggleMute,
}: {
  isMuted: boolean
  isOnHold: boolean
  onEndCall: () => Promise<void>
  onToggleHold: () => Promise<void>
  onToggleMute: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <>
      <DelayedClickButton
        type="button"
        variant="outline"
        className="w-[126px]"
        onClick={onToggleHold}
      >
        <Icon name={isOnHold ? "resume-call" : "hold-call"} />
        {t(`global.button.${isOnHold ? "resume" : "hold"}`)}
      </DelayedClickButton>
      <Button
        type="button"
        variant="outline"
        className="w-[156px]"
        onClick={onToggleMute}
      >
        <Icon name={isMuted ? "unmute-call" : "mute-call"} />
        {t(`global.button.${isMuted ? "unmute" : "mute"}`)}
      </Button>
      <SingleClickButton
        variant="destructive"
        type="button"
        onClick={onEndCall}
      >
        <Icon name="end-call" />
        {t("global.button.endCall")}
      </SingleClickButton>
    </>
  )
}

export function ConnectedTaskButtons({
  onCall,
  onSkipCall,
}: {
  onCall: () => Promise<void>
  onSkipCall: () => void
}) {
  const { t } = useTranslation()

  return (
    <>
      <Button variant="destructive" type="button" onClick={onSkipCall}>
        <Icon name="end-call" />
        {t("global.button.wontCall")}
      </Button>
      <SingleClickButton type="button" variant="success" onClick={onCall}>
        <Icon name="accept-call" />
        {t("global.button.call")}
      </SingleClickButton>
    </>
  )
}

export function SkipCallModeButtons({
  isDisabled,
  isLoading,
  onEndTask,
  onGoBack,
}: {
  isDisabled: boolean
  isLoading: boolean
  onEndTask: () => Promise<void>
  onGoBack: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <>
      <Button variant="outline" onClick={onGoBack}>
        <ChevronLeftIcon />
        {t("global.button.goBack")}
      </Button>
      <Button isDisabled={isDisabled} isLoading={isLoading} onClick={onEndTask}>
        {t("global.button.endTask")}
      </Button>
    </>
  )
}

export function ACWButtons({
  isDisabled,
  isLoading,
  isManualCall = false,
  isPseudoACW,
  isVoiceContact,
  onClick,
}: ReturnType<typeof useACWButtonsHook> & {
  isDisabled: boolean
  isManualCall?: boolean
}) {
  const { t } = useTranslation()

  // When you cancel an outbound call before connecting, the contact is a task
  // but we have the pseudo ACW state
  if (!isVoiceContact && !isPseudoACW) {
    return null
  }

  return (
    <Button
      type="button"
      onClick={async () => onClick(true)}
      isDisabled={isDisabled}
      isLoading={isLoading}
    >
      {t(
        isManualCall
          ? "global.button.confirmAndReturn"
          : "global.button.endTask",
      )}
    </Button>
  )
}

function ErrorButtons({
  displayModeMessage,
  onEndTaskError,
}: {
  displayModeMessage: ErrorStateMessage
  onEndTaskError: () => Promise<void>
}) {
  const { t } = useTranslation()

  return (
    <Button type="button" onClick={onEndTaskError}>
      {displayModeMessage === "missed_call_agent_state"
        ? t("global.button.close")
        : t("global.button.endTask")}
    </Button>
  )
}

export function ManualCallIdleButtons({
  campaignName,
  onAddStatus,
  onCall,
}: {
  campaignName: string
  onAddStatus: () => Promise<void>
  onCall: (queueARN: string) => Promise<void>
}) {
  const { t } = useTranslation()
  const { fetchCampaignQuery, fetchQueueARNQuery } =
    useGetEnabledCallConditions(campaignName)
  const queueARN = fetchQueueARNQuery?.data?.data.queue_arn
  const campaignEnabled = fetchCampaignQuery?.data?.data.enabled || false
  const isLoading =
    fetchQueueARNQuery?.isLoading && fetchCampaignQuery?.isLoading

  const handleCall = async () => {
    if (!queueARN) return
    await onCall(queueARN)
  }
  const enableCall = campaignEnabled && queueARN
  const tooltip = campaignEnabled
    ? queueARN
      ? undefined
      : t("callPanel.reason.invalidQueueARN")
    : t("callPanel.reason.disabledCampaign")

  return (
    <>
      <Button onClick={onAddStatus} variant="outline">
        {t("global.button.addStatus")}
      </Button>
      <TooltipWrapper tooltip={tooltip}>
        <SingleClickButton
          isLoading={isLoading}
          isDisabled={!enableCall}
          onClick={handleCall}
        >
          {t("global.button.call")}
        </SingleClickButton>
      </TooltipWrapper>
    </>
  )
}
