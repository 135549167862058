import { z } from "zod"

import { useQueryAPI } from "@/hooks/query-api"
import * as connectPanelValue from "@/pages/CustomConnectPanel/value"

const schema = z.object({
  data: z.object({
    case: connectPanelValue.Decoder.caseSchema,
    activity_feed: z.array(
      connectPanelValue.ActivityFeedEntry.activityFeedSchema,
    ),
  }),
})

export type ActivityFeedEntry = z.infer<
  typeof connectPanelValue.ActivityFeedEntry.activityFeedSchema
>

export type CaseWithActivityFeed = z.infer<typeof schema>["data"]

export function useFetchStudentCase(campaign_id: string, student_id: string) {
  return useQueryAPI({
    apiEndPoint: `/case`,
    params: { campaign_id, student_id },
    schema,
  })
}

export function useFetchStudentProfile(student_id: string) {
  return useQueryAPI({
    apiEndPoint: `/students`,
    params: { student_id },
    schema: z.object({ data: connectPanelValue.Decoder.studentProfileSchema }),
  })
}
