import * as callViewValue from "@/components/CallView/value"
import {
  DecodeProcessingTimeData,
  decodeProcessingTimeDataSchema,
  EncodeProcessingTimeData,
} from "@/helpers/zodSchemas"

import LocalStorageService from "./localStorageService"
/**
 * Service to handle CRUD operations in local storage for contact related data.
 */
class ContactStorageService extends LocalStorageService {
  private readonly AGENT_HAS_CALLED_KEY = "agent_has_called"
  private readonly CALL_REPORT_KEY = "call_report"
  private readonly PSEUDO_ACW_KEY = "pseudo_acw"
  private readonly SAVED_CALL_REPORT_KEY = "saved_call_report"
  private readonly PROCESSING_TIME_DATA = "processing_time_data"
  private readonly CALL_DURATION_DATA = "call_duration_data"
  private readonly MANUAL_CALL = "manual_call"

  private static instance: ContactStorageService

  static getInstance(): ContactStorageService {
    if (!ContactStorageService.instance) {
      ContactStorageService.instance = new ContactStorageService()
    }

    return ContactStorageService.instance
  }

  setAgentHasCalled(value: boolean): void {
    super.setItem(this.AGENT_HAS_CALLED_KEY, value)
  }

  getAgentHasCalled(): boolean {
    const storedValue = super.getItem(this.AGENT_HAS_CALLED_KEY)

    return storedValue === "true"
  }

  removeAgentHasCalled(): void {
    super.removeItem(this.AGENT_HAS_CALLED_KEY)
  }

  // We split the call report into two different keys to avoid edge cases when updating and saving the call report
  // e.g. updated the form but didn't click save
  setCallReport({
    isSaved = false,
    value,
  }: {
    isSaved?: boolean
    value: callViewValue.Decoder.DecoderType
  }): void {
    const localStorageKey = isSaved
      ? this.SAVED_CALL_REPORT_KEY
      : this.CALL_REPORT_KEY

    return super.setItem(localStorageKey, value)
  }

  getCallReport(isSaved: boolean): unknown {
    const localStorageKey = isSaved
      ? this.SAVED_CALL_REPORT_KEY
      : this.CALL_REPORT_KEY

    return super.getItem(localStorageKey)
  }

  removeCallReport(): void {
    const keys = [this.CALL_REPORT_KEY, this.SAVED_CALL_REPORT_KEY]
    keys.forEach((key) => super.removeItem(key))
  }

  getProcessingTimeData(): DecodeProcessingTimeData | null {
    const storedValue = super.getItem(this.PROCESSING_TIME_DATA)
    const parsedValueResponse =
      decodeProcessingTimeDataSchema.safeParse(storedValue)

    return parsedValueResponse.success ? parsedValueResponse.data : null
  }

  setProcessingTimeData(contactId: string, previousDuration: number): void {
    const newTimeData: EncodeProcessingTimeData = {
      updated_at_timestamp: new Date().toISOString(),
      contactId,
      prev_duration: previousDuration,
    }

    super.setItem(this.PROCESSING_TIME_DATA, newTimeData)
  }

  removeProcessingTimeData(): void {
    super.removeItem(this.PROCESSING_TIME_DATA)
  }

  updateProcessingDuration({
    contactId,
    duration,
    previousTimeData,
  }: {
    contactId: string
    duration: number
    previousTimeData: DecodeProcessingTimeData
  }): void {
    const updatedTimeData: EncodeProcessingTimeData = {
      contactId,
      prev_duration: duration,
      updated_at_timestamp: previousTimeData.updated_at_timestamp.toISOString(),
    }

    super.setItem(this.PROCESSING_TIME_DATA, updatedTimeData)
  }

  clear(shouldClearAllState = false): void {
    // Will not remove the PROCESSING_TIME_DATA and CALL_DURATION_DATA keys on log-out while using the
    // automatic call panel because the agent might still have an active contact when logging back in
    const baseKeys = [
      this.AGENT_HAS_CALLED_KEY,
      this.CALL_REPORT_KEY,
      this.SAVED_CALL_REPORT_KEY,
    ]

    const keys = shouldClearAllState
      ? [...baseKeys, this.PROCESSING_TIME_DATA]
      : baseKeys

    keys.forEach((key) => super.removeItem(key))
  }

  getPseudoACWKey(): string {
    return this.PSEUDO_ACW_KEY
  }

  getCallDurationKey(): string {
    return this.CALL_DURATION_DATA
  }

  getManualCallKey(): string {
    return this.MANUAL_CALL
  }
}

const contactStorageService = ContactStorageService.getInstance()

export default contactStorageService
