import { useRef } from "react"
import debounce from "lodash/debounce"
import { XIcon } from "lucide-react"

import Input from "@/components/core/Input"
import Icon from "@/components/Icon"
import { Button } from "@/components/ui/button"

import { useNoCallListSearchOptions } from "./search-options"

type Props = {
  searchState: ReturnType<typeof useNoCallListSearchOptions>
}

export function NoCallListSearchBar({ searchState }: Props) {
  const { onChange, searchOptions } = searchState

  return (
    <SearchBox
      defaultValue={searchOptions.search_query || ""}
      onChange={onChange("search_query")}
    />
  )
}

/**
 * Uncontrolled input to search by name or phone, we don't need state here
 */
function SearchBox({
  defaultValue,
  onChange,
}: {
  defaultValue: string
  onChange: (text: string) => void
}) {
  const ref = useRef<HTMLInputElement | null>(null)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    onChange(event.target.value)
  }
  function handleReset() {
    if (ref.current) ref.current.value = ""
    onChange("")
  }

  const debouncedChange = debounce(handleChange, 300)

  return (
    <div className="relative">
      <Input
        forwardedRef={ref}
        name="search"
        defaultValue={defaultValue}
        onChange={debouncedChange}
        target={<Icon name="search" />}
        inputClassName="h-10 min-w-[300px]"
        placeholder="dashboard.selectOptions.searchPlaceholder"
      />
      <Button
        onClick={handleReset}
        variant="ghost"
        size="icon"
        className="absolute right-2 top-2 size-6 max-w-[300px]"
      >
        <XIcon className="size-4" />
      </Button>
    </div>
  )
}
